.navigation {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  overflow: hidden !important;
  height: 100vh; //-webkit-fill-available !important;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}